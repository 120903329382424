// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, H4 } from '@tackl';

// Exports
// ------
export const Jacket = styled.li(
	(props) => css`
		border-bottom: 1px solid ${props.theme.white};
	`
);

export const Title = styled(H4)(
	(props) => css`
		transition: ${props.theme.ease};
		font-size: clamp(1.6rem, 4vw, 2.2rem);
	`
);

export const Link = styled.a(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 2.4rem 0;

		svg {
			transition: ${props.theme.ease};
		}

		&:hover {
			${Title},
			svg {
				opacity: 0.4;
			}
		}
	`
);
