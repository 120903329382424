// Imports
// ------
import React from 'react';
import SingleCareer from './SingleCareer';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------
import {
	Jacket,
	Join,
	JoinTitle,
	JoinContent,
	JoinImage,
	Careers,
	CareersTitle,
	CareersList,
	NoCareers,
} from './styles';

// Component
// ------
const CareersListing = ({ join, careers }) => {
	const bp = useBreakpoint();

	return (
		<Jacket pad>
			<Row isExpanded isCollapsed sidePad isEqual>
				<Column small={12} large={6}>
					<Row isExpanded>
						<Column small={12} large={9} mpad>
							<Join>
								<JoinTitle>{join[0]}</JoinTitle>
								<JoinContent>{join[1]}</JoinContent>
							</Join>
						</Column>
					</Row>

					<Row isExpanded>
						<Column mpad>
							<Careers>
								<CareersTitle>Roles Available</CareersTitle>
								<CareersList>
									{careers.length !== 0 ? (
										careers.map((block, i) => (
											<SingleCareer
												key={i}
												title={block.node.title}
												link={block.node.link}
											/>
										))
									) : (
										<NoCareers>
											There are no roles available currently, check back later.
										</NoCareers>
									)}
								</CareersList>
							</Careers>
						</Column>
					</Row>
				</Column>

				{bp.medium ? null : (
					<Column small={12} pushOnLarge={1} large={5} mpad>
						<JoinImage>
							<GatsbyImage
								image={join[2].gatsbyImageData}
								alt={join[2] ? join[2].alt : 'Client Logo'}
							/>
						</JoinImage>
					</Column>
				)}
			</Row>
		</Jacket>
	);
};

export default CareersListing;
