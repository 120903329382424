// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, H3, H4, P } from '@tackl';
import { pStyles } from '@type';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		background: linear-gradient(-90deg, #303339 -100%, #080809 50%);
	`
);

export const Join = styled.div(
	(props) => css`
		${breakup.large`
			margin: 0 0 4rem;
		`}
	`
);

export const JoinTitle = styled(H3)(
	(props) => css`
		font-weight: 400;
		margin: 0 0 2.5rem;

		${breakup.large`
			font-size: 5rem;
        `}
	`
);

export const JoinContent = styled(P)(
	(props) => css`
		//-
	`
);

export const JoinImage = styled.div(
	(props) => css`
		position: relative;
		height: 100%;
		overflow: hidden;
		border-radius: 1rem;

		.gatsby-image-wrapper {
			width: 100%;
			height: 100%;
		}
	`
);

export const Careers = styled.div(
	(props) => css`
		${breakup.large`
			margin-top: 9.6rem;
		`}
	`
);

export const CareersTitle = styled(H3)(
	(props) => css`
		margin: 0 0 2.4rem;
		font-weight: 400;
		font-size: clamp(2rem, 5vw, 3.4rem);

		${breakup.large`
            margin: 0 0 3.6rem;
        `}
	`
);

export const CareersList = styled.ul(
	(props) => css`
		border-top: 1px solid ${props.theme.white};
	`
);

export const NoCareers = styled.li(
	(props) => css`
		${pStyles}

		padding: 2.4rem 0;
		border-bottom: 1px solid ${props.theme.white};
	`
);
