// Imports
// ------
import React, { useEffect } from 'react';
import Seo from '@lay/Seo';
import PageTransition from '@parts/PageTransition';
import CareersListing from '@parts/CareersListing';
import Newsletter from '@parts/Newsletter';
import Hero from '@parts/Hero/Careers';
import Footer from '@parts/Footer';
import { graphql } from 'gatsby';
import { headerState } from '@states/header';
import { action } from 'mobx';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Component
// ------
function Careers({ transitionStatus, entry, exit, data }) {
	const bp = useBreakpoint();
	const ts = transitionStatus;
	const { title, joinHeading, joinContent, joinImage, label } = data.page;

	const titleAlt = `Curious & passionate about 3D? We would love to hear from you`;

	useEffect(() => {
		const hideNotification = action(() => {
			headerState.notifyActive = false;
			headerState.notifySize = 0;
		});

		hideNotification();
	}, []);

	useEffect(() => {
		const timer = setTimeout(
			() => {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			},
			bp.large ? 500 : 1000
		);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<>
			<Seo data={data.page.seo} backupData={data.globalSeo} />
			<PageTransition status={ts} entry={entry} exit={exit}>
				<Hero title={title ? title : titleAlt} label={label} />
				<CareersListing
					join={[joinHeading, joinContent, joinImage]}
					careers={data.posts.edges}
				/>
				<Newsletter />
				<Footer />
			</PageTransition>
		</>
	);
}

export default Careers;

// GraphQL
// ------
export const query = graphql`
	query CareersListing {
		globalSeo: datoCmsOptionsGlobal {
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
		}

		page: datoCmsCareersListing {
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
			label
			title
			joinHeading
			joinContent
			joinImage {
				gatsbyImageData(
					width: 550
					placeholder: BLURRED
					forceBlurhash: false
					layout: FULL_WIDTH
					backgroundColor: ""
				)
				alt
				smartTags
			}
		}

		posts: allDatoCmsCareersPost {
			edges {
				node {
					title
					link
				}
			}
		}
	}
`;
