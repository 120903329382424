// Imports
// ------
import React from 'react';
import Icon from '@icons';

// Styles
// ------
import { Jacket, Link, Title } from './styles';

// Component
// ------
const SingleCareer = ({ title, link }) => (
	<Jacket>
		<Link href={link} target='_blank' aria-label='View Career'>
			<Title weight='semi'>{title}</Title>
			<Icon type='arrow-external' />
		</Link>
	</Jacket>
);

export default SingleCareer;
