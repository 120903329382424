// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, H1 } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) =>
		css`
			background: linear-gradient(-90deg, #303339 -100%, #080809 50%);

			${breakup.xlarge` margin-bottom: -4rem; `}
		`
);

export const Title = styled(H1)(
	(props) => css`
		font-size: clamp(4rem, 6vw, 7rem);
		max-width: 8em;
		font-weight: 600;
		text-align: center;
		margin: 0 auto;
	`
);
